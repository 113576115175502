<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br />
    <div class="form-container--small">
      <label>Pilih Jenjang</label><br />
      <b-form-select
        v-model="formData.jenjang"
        :options="jenjangs"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>
      <br />
      <br /><br />

      <label>Pilih Mata Pelajaran atau Kategori</label><br />
      <b-form-select
        v-model="formData.category"
        :options="categories"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>
      <br /><br /><br />

      <label>Pilih Topik atau Bab</label><br />
      <b-form-select
        v-model="formData.topic"
        :options="topics"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>
      <br /><br /><br />

      <label>Nama Playlist</label><br />
      <b-form-input
        placeholder="Contoh: Pengertian Vektor"
        v-model="formData.name"
      ></b-form-input
      ><br /><br />

      <div class="upload-image">
        <div class="upload-image__item">
          <div class="upload-image__top">
            <label>Icon Preview</label> <br />
            <div class="cat-icon-container">
              <img
                :src="'/img/category-icons/' + formData.icon.img"
                alt="img"
                class="selected-icon"
              />
            </div>
          </div>

          <div class="upload-image__bottom">
            <button type="button" class="set-icon" v-b-modal.modal-icon>
              SET ICON
            </button>
          </div>
        </div>

        <div class="upload-image__item mrl-50">
          <div class="upload-image__top">
            <label>Free</label>
          </div>

          <div class="upload-image__bottom">
            <b-form-checkbox
              v-model="formData.isFree"
              name="check-button"
              switch
              @click="handleSwitchDataChange"
            >
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal-icon"
      id="modal-icon"
      hide-header-close
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <img
        src="/img/close-button.svg"
        alt="X"
        @click="hideModal('modal-icon')"
        class="close-button"
      />
      <br />
      <h2 class="txt-center color2">Set Icon</h2>
      <br />

      <div class="cat-icon flex">
        <div class="cat-icon__item" v-for="(icon, i) in icons" :key="i">
          <img
            :src="'/img/category-icons/' + icon.img"
            alt=""
            class="cat-icon-img"
            :class="{ 'cat-icon-img': icon.img === formData.icon.img }"
            @click="setIcon(icon)"
          />
          <br />
          <p class="cat-icon-name">
            {{ icon.name }}
          </p>
        </div>
      </div>
      <br /><br />
    </b-modal>

    <b-modal
      ref="modal-confirm"
      id="modal-confirm"
      hide-header-close
      hide-header
      hide-footer
      size="sm"
      centered
    >
      <img
        src="/img/close-button.svg"
        alt="X"
        @click="batalNow"
        class="close-button"
      />
      <br /><br />

      <p>
        Apakah anda yakin mengubah status playlist <br />
        ini menjadi
        <strong class="color-black" v-if="!formData.isFree">
          Premium?
        </strong>

        <strong class="color-black" v-if="formData.isFree">
          Free?
        </strong>
      </p>

      <button class="e-button e-button--not-rounded button-ya" @click="yaNow">
        YA
      </button>

      <button
        class="e-button bg-color2 e-button--not-rounded"
        @click="batalNow"
      >
        BATAL
      </button>

      <br /><br />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jenjangs: [
        { text: "Select One", value: null },
        { text: "SBMPTN", value: "SBMPTN" },
        { text: "Kelas X", value: "Kelas X" },
        { text: "Kelas XI", value: "Kelas XI" },
        { text: "Kelas XII - IPA", value: "Kelas XII - IPA" },
        { text: "Kelas XII - IPS", value: "Kelas XII - IPS" }
      ],
      categories: [
        { text: "Select One", value: null },
        { text: "Matematika IPA", value: "Matematika IPA" },
        { text: "Bahasa Indonesia", value: "Bahasa Indonesia" },
        { text: "Kimia", value: "Kimia" },
        { text: "Fisika", value: "Fisika" }
      ],
      topics: [
        { text: "Select One", value: null },
        { text: "Vektor", value: "Vektor" },
        { text: "Program Linier", value: "Program Linier" },
        { text: "Matriks", value: "Matriks" },
        { text: "Vektor", value: "Vektor" }
      ],
      icons: [
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" },
        { img: "sampleicon1.svg", name: "Sport" },
        { img: "sampleicon2.svg", name: "Health" },
        { img: "sampleicon3.svg", name: "Movie" }
      ],
      formData: {
        jenjang: null,
        category: null,
        topic: null,
        name: "",
        icon: { name: "default", img: "thumbnail-default.svg" },
        isFree: true
      }
    };
  },

  methods: {
    setIcon() {},
    hideModal(modalName) {
      this.$bvModal.hide(modalName);
    },
    setIcon(icon) {
      this.formData.icon = icon;
      this.$bvModal.hide("modal-icon");
    },
    handleSwitchDataChange() {
      console.log("Handling...");
    },

    batalNow() {
      this.formData.isFree = !this.formData.isFree;
      setTimeout(() => {
        this.$bvModal.hide("modal-confirm");
      }, 11);
    },

    yaNow() {
      this.$bvModal.hide("modal-confirm");
    },

    handleHide(e) {
      e.preventDefault();
    }
  },

  watch: {
    "formData.isFree": function(newVal, oldVal) {
      this.$bvModal.show("modal-confirm");
    }
  }
};
</script>

<style>
.cat-icon-container {
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f8ff;
}

.cat-icon-name {
}

.cat-icon-img {
  padding: 11px;
  cursor: pointer;
}

.cat-icon-img:hover,
.cat-icon-img--active {
  background: #d8f2ff;
}

.selected-icon {
  width: 49px;
}

.button-ya {
  border: 1px solid #3bbaed;
  color: #3bbaed;
  margin-right: 11px;
  background: #fff;
  min-width: 22px;
  margin-top: 11px;
}

@media (min-width: 576px) {
  #modal-icon .modal-lg {
    max-width: 544px;
  }
  #modal-confirm .modal-sm {
    max-width: 430px;
    text-align: center;
    font-size: 14px;
    color: #000;
    line-height: 1.7;
  }
}
</style>
